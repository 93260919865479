import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
});

export const store = new Vuex.Store({
    state: {
        show: 0,
        project: {},
        profileUrl: "",
        download: true,
        allActivitySubGroups: null,
        user: null,
        highlightsProject:{},

        accessToken: null,
        email: null
    },
    mutations: {
        setProject(state, data) {
            state.project = data;
            console.log("setProject", data);
        },
        setHighlightsProject(state, data) {
            state.highlightsProject = data;
            console.log("setHighlightsProject", data);
        },
        setCloneProject(state, data) {
            state.cloneProject = data;
            console.log("setCloneProject", data);
        },
        setDownload(state, data){
            state.download = data;
            console.log("setDownload", data);
        },
        setAllActivitySubGroups(state, data){
            state.allActivitySubGroups = data;
            console.log("setAllActivitySubGroups", data);
        },

        setUser(state, data){
            state.user = data;
            console.log("setUser", data);
        },
        setAccessToken(state, accessToken) {
            state.accessToken = accessToken;
        }
    },
    getters: {
        show: (state) => state.show,
        download: (state) => state.download,

        projectId: (state) => state.project._id,
        projectName: (state) => state.project.project_name,

        highlightsProjectId: (state) => state.highlightsProject._id,
        highlightsProject:(state)=>state.highlightsProject,

        cloneProject: (state) => state.cloneProject,

        allActivitySubGroups: (state) => state.allActivitySubGroups,

        user: (state) => state.user,
        email: (state) => state.user.email,
        accessToken: (state) => {
            return state.accessToken;
        }
    },
    plugins: [vuexLocal.plugin]
});