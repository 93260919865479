<template>
    <div>
        <Loader v-if="csv_loading" />

        <ModalEditSelection v-if="$store.getters.projectId" v-bind:show="show" display="Edit Project" />

        <!-- NavBar components -->
        <b-navbar container-classes="container-fluid" class="navbar-top navbar-expand cust-shadow nav-pad bg-white"
            :class="{ 'navbar-dark': type === 'default' }">

            <!-- Home -->
            <router-link to="projects"
                class="h4 mb-0 text-white text-uppercase d-lg-inline-block active router-link-active">
                <img src="../assets/img/c-balance.png" width="200" />
            </router-link>

            <!-- Project Name -->
            <b-navbar-nav v-if="$route.name == 'table' || $route.name == 'explore' || $route.name == 'entities'"
                class="align-items-center ml-md-auto" style="display:flex; justify-content: center;">
                <li class="nav-item m-l-1r">
                    <span class="mb-2 h3 btn" v-b-modal.modal-edit-project>
                        {{ $store.getters.projectName }}
                        <b-icon icon="pencil" class="ml-2 pencil" />
                    </span>
                </li>
            </b-navbar-nav>

            <!-- Project Action Items -->
            <b-navbar-nav class="align-items-center ml-md-auto">

                <!-- Edit Project Selection -->
                <li id="add-efdb" v-if="$route.name == 'projects'" class="nav-item m-l-1r">
                    <button @click="openManageEFDB()" class="mb-0 btn h1">
                        <img style="height: 24px" src="../assets/img/database-add.svg" />
                    </button>
                    <b-tooltip :trigger="'hover'" target="add-efdb" placement="top" variant="info">
                        Add a new EFDB
                    </b-tooltip>
                </li>

                <!-- Manage Collaborators -->
                <li id="manage-collaborators" v-if="$route.name == 'table'" class="nav-item m-l-1r">
                    <button @click="openManageCollaborators()" class="mb-0 btn h1">
                        <img style="height: 24px" src="../assets/img/add_user_icon.svg" />
                    </button>
                    <b-tooltip :trigger="'hover'" target="manage-collaborators" placement="top" variant="info">
                        Manage Collaborators
                    </b-tooltip>
                </li>

                <!-- Add/Edit Entities -->
                <li id="addeditentity" v-if="$route.name == 'table'" class="nav-item m-l-1r">
                    <router-link to="entities"
                        class="h4 mb-0 text-white text-uppercase d-lg-inline-block active router-link-active">
                        <button class="mb-0 btn h1">
                            <b-icon icon="plus-circle" variant="dark"></b-icon>
                        </button>
                        <b-tooltip :trigger="'hover'" target="addeditentity" placement="top" variant="info">
                            Add/Edit Entities
                        </b-tooltip>
                    </router-link>
                </li>

                <!-- View Project Highlights -->
                <li id="projectHighlights" name="projectHighlights" v-if="$route.name == 'table' || $route.name == 'explore' || $route.name == 'entities'"
                    class="nav-item m-l-1r">
                    <button @click="setProjectStats" class="mb-0 btn h1">
                        <img src="../assets/img/pie_chart_icon.svg" />
                    </button>
                    <b-tooltip :trigger="'hover'" target="projectHighlights" placement="top" variant="info">
                        View Project Highlights
                    </b-tooltip>
                </li>

                <!-- View Activities -->
                <li v-if="$route.name == 'table' || $route.name == 'explore' || $route.name == 'entities'" class="nav-item m-l-1r">
                    <b-button @click="viewActivities" variant="primary">View Activities</b-button>
                </li>

                <!-- Export LCA Data -->
                <li id="downloadCsv" name="downloadCsv" v-if="$route.name == 'table' || $route.name == 'explore'"
                    class="nav-item m-l-1r">
                    <button :disabled="!$store.getters.download" @click="downloadCSV" class="mb-0 btn h1">
                        <b-icon icon="download" variant="dark"></b-icon>
                    </button>
                    <b-tooltip :trigger="'hover'" target="downloadCsv" placement="top" variant="info">
                        Export LCA Data
                    </b-tooltip>
                    <b-tooltip v-if="!$store.getters.download" :trigger="'hover'" target="downloadCsv" placement="top"
                        variant="danger">
                        Kindly fix all validation error messages raised in the table
                    </b-tooltip>
                </li>

                <!-- Import LCA Data -->
                <li id="importCSV" name="importCSV" v-if="$route.name == 'table'" class="nav-item m-l-1r">
                    <input type="file" name="file" accept=".csv, text/csv" id="importCSVInput" @change="importCSV($event)"/>
                    <label for="importCSVInput" id="importCSVLabel" class="mb-0 btn h1">
                        <b-icon icon="upload" variant="dark"></b-icon>
                    </label>
                    <b-tooltip :trigger="'hover'" target="importCSV" placement="top" variant="info">
                        Import LCA Data
                    </b-tooltip>
                    <b-tooltip
                        v-if="!$store.getters.download"
                        :trigger="'hover'"
                        target="importCSV"
                        placement="top"
                        variant="danger"
                    >
                        Kindly fix all validation error messages raised in the table
                    </b-tooltip>
                </li>

                <!-- Edit Project Selection -->
                <li id="editselection" v-if="$route.name == 'table' || $route.name == 'explore'"
                        class="nav-item m-l-1r">
                    <button v-b-modal.modal-edit-project-category class="mb-0 btn h1">
                        <b-icon icon="gear" variant="dark"></b-icon>
                    </button>
                    <b-tooltip :trigger="'hover'" target="editselection" placement="top" variant="info">
                        Edit Project Selection
                    </b-tooltip>
                </li>
            </b-navbar-nav>

            <!-- Logout -->
            <b-navbar-nav class="align-items-center ml-auto ml-md-0">
                <b-nav-item-dropdown right class="nav-item" tag="li" title-tag="a" title-classes="nav-link pr-0">
                    <template #button-content>
                        <div class="nav-link pr-0" slot="title-container">
                            <b-media no-body class="align-items-center">
                                <span class="avatar avatar-sm rounded-circle">
                                    <img alt="P" :src="profile_pic" />
                                </span>
                                <b-media-body class="ml-2 d-lg-block">
                                    <span class="mb-0 text-sm font-weight-bold">
                                        <b-icon icon="caret-down-fill" variant="dark"></b-icon>
                                    </span>
                                </b-media-body>
                            </b-media>
                        </div>
                    </template>
                    <b-dropdown-item>
                        <router-link to="login">
                            <b-dropdown-item @click="logout">
                                <i class="ni ni-user-run"></i>
                                <span>Logout</span>
                            </b-dropdown-item>
                        </router-link>
                    </b-dropdown-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>

        </b-navbar>

        <!-- View Activities Modal -->
        <b-modal id="modal-activity" v-model="showActivity" v-if="showActivity" size="xl">
            <b-table :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter"
                :filter-included-fields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection" stacked="md" show-empty small bordered hover no-border-collapse
                :busy="table_loading">
                <template #table-busy>
                    <div class="text-center my-2">
                        <b-spinner class="align-middle mr-3"></b-spinner>
                        <strong>Loading Activities...</strong>
                    </div>
                </template>
                <template #cell(act)="data">
                    <div class="activities-main">
                        <div class="activities-div">
                            <span class="activities" v-for="item of data.value" v-bind:key="item">{{ item }}</span>
                        </div>
                    </div>
                </template>
            </b-table>
            <template #modal-footer>
                <div class="w-100 text-center">
                    <b-button class="px-3 py-2" variant="primary" size="sm" @click="showActivity = false">
                        Close
                    </b-button>
                </div>
            </template>
        </b-modal>

        <ManageEFDBModal v-bind:loading="loading" />
        <ProjectHighlightsModal v-bind:loading="loading" />
        <ManageCollaboratorsModal v-bind:loading="loading" />
    </div>
</template>

<script>
import Swal from "sweetalert2";
import firebase from "firebase";

import Loader from "./Loader";
import ManageEFDBModal from "./ManageEFDB.vue";
import ModalEditSelection from "./EditSelectionModal";
import ManageCollaboratorsModal from "./ManageCollaborators.vue";
import ProjectHighlightsModal from "./ProjectHighlightsModal.vue";


export default {
    components: {
        Loader,
        ManageEFDBModal,
        ModalEditSelection,
        ProjectHighlightsModal,
        ManageCollaboratorsModal
    },
    props: {
        type: {
            type: String,
            default: "default", // default|light
            description: "Look of the dashboard navbar. Default (Green) or light (gray)",
        },
    },
    computed: {
        routeName() {
            const { name } = this.$route;
            return this.capitalizeFirstLetter(name);
        },
    },
    data() {
        return {
            show: 0,
            modalOpen: false,
            loading: false,
            projectId: this.$store.getters.projectId,
            projectName: this.$store.getters.projectName,
            activeNotifications: false,
            showMenu: false,
            searchModalVisible: false,
            searchQuery: "",
            showActivity: false,
            table_loading: false,
            csv_loading: false,
            profile_pic: "",
            fields: [
                {
                    key: "at",
                    label: "Activity Types",
                    class: "text-center",
                },
                {
                    key: "act",
                    label: "Activities",
                    class: "text-center",
                },
            ],
            items: [],
        };
    },
    mounted: async function () {
        // Callback to set Project Details
        this.$root.$on("setProjectDetails", async (project) => {
            console.log("projectname", project);
            this.projectId = await project._id;
            this.projectName = await project.project_name;
        });
    },
    methods: {
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        toggleNotificationDropDown() {
            this.activeNotifications = !this.activeNotifications;
        },
        closeDropDown() {
            this.activeNotifications = false;
        },
        logout() {
            firebase
                .auth()
                .signOut()
                .then(() => {
                    console.log("Sign-out successful.");
                    this.$router.push("login");
                })
                .catch((error) => {
                    console.log("Sign-out error happened.", error);
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.message,
                    });
                });
        },
        viewActivities() {
            this.showActivity = true;
            this.getActivities(this.$store.getters.projectId);
        },
        downloadCSV() {
            this.getCSV(this.$store.getters.projectId);
        },
        async importCSV(event) {
            this.csv_loading = true;

            let formData = new FormData();
            // formData.append('NAME', file);
            // formData.append(event.target.files[0].name, event.target.files[0]);
            formData.append("file", event.target.files[0]);
            // console.log(event.target.files[0].name);
            // console.log(event.target.files[0]);
            // console.log(formData);

            this.axios
                .post(`/importProjectCSV?project_id=${this.$store.getters.projectId}`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                .then((response) => {
                    console.log(response);
                    this.csv_loading = false;
                    // this.$router.go();

                    this.$toasted.success("Your CSV has been successfully uploaded !!");

                    this.$router.push("/projects");
                    // this.$router.push(this.$router.currentRoute);
                })
                .catch(error => {
                    this.csv_loading = false;
                    console.log("importCSV err", error);
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.message,
                    });
                });

        },
        getActivities(project_id) {
            this.table_loading = true;
            this.axios
                .get(`/getActivities?project_id=${project_id}`)
                .then((res) => {
                    this.items = [];
                    console.log("getActivities res", res);
                    let data = res.data.data;
                    for (let type in data) {
                        let obj = {
                            at: type,
                            act: data[type],
                        };
                        console.log(type, data.type);
                        this.items.push(obj);
                    }
                    this.table_loading = false;
                })
                .catch((err) => {
                    console.log("getActivities err", err);
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: err.message,
                    });
                    this.table_loading = false;
                });
        },
        getCSV(project_id) {
            this.csv_loading = true;
            this.axios
                .get(`/downloadProjectCSV?project_id=${project_id}`)
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', this.projectName + "-input.csv");
                    document.body.appendChild(link);
                    link.click();
                    this.csv_loading = false;
                })
                .catch(error => {
                    this.csv_loading = false;
                    console.log("getProjectCsv err", error);
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.message,
                    });
                });
        },
        downloadURI(uri, name) {
            var link = document.createElement("a");
            link.download = name;
            link.href = uri;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            link = null;
        },
        async setProjectStats() {
            this.$root.$emit('bv::show::modal', 'modal-project-highlights');
        },
        async openManageCollaborators() {
            this.$root.$emit('bv::show::modal', 'modal-manage-collaborators');
        },
        async openManageEFDB() {
            this.$root.$emit('bv::show::modal', 'modal-manage-efdb');
        }
    },
    created: async function () {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.profile_pic = user.photoURL;
            }
        });
    },
};
</script>

<style scoped lang="scss">
.cust-shadow {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}

.nav-pad {
    padding: 0.3rem 1rem !important;
}

.m-l-1r {
    padding-left: 12px;
}

.pencil {
    cursor: pointer;
    border: 0px;
}

.bg-white {
    background-color: white;
}

.activities-main {
    overflow: hidden;
    white-space: break-spaces;
    text-align: left;

    .activities-div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}
#importCSVInput {
    display: none
}
.import-csv-label {
    cursor: not-allowed
}
</style>
<style>
.btn:disabled {
    cursor: not-allowed;
}

.dropdown-toggle::after {
    display: none !important;
}
</style>