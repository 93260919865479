<template>
    <div class="">
        <Loader v-if="loading" />

        <b-modal @show="getEmissionStats" @hide="hideProjectHighlightsModal" content-class="highlight-modal-content"
            dialog-class="highlight-modal-dialogue" hide-header hide-footer centered id="modal-project-highlights">
            <h1 class="highlight-modal-title">Project Highlights</h1>
            <div class="padding-40">
                <b-row class="stats-container">
                    <b-col cols="6">
                        <h2 class="title">Total Emissions</h2>
                    </b-col>
                    <b-col cols="6">
                        <h2 class="mb-40">{{ emissionStats.total + "  kg CO2e/yr" }}</h2>
                    </b-col>
                    <b-col cols="12">
                        <h2 class="title mb-30">Emissions Per Unit Type:</h2>
                    </b-col>
                    <b-col cols="6">
                        <h3 class="title">Electrical Energy</h3>
                    </b-col>
                    <b-col cols="6">
                        <h3>{{ emissionStats.electrical + "  kg CO2e/yr" }}</h3>
                    </b-col>
                    <b-col cols="6">
                        <h3 class="title">Thermal Energy</h3>
                    </b-col>
                    <b-col cols="6">
                        <h3>{{ emissionStats.thermal + "  kg CO2e/yr" }}</h3>
                    </b-col>
                    <b-col cols="6">
                        <h3 class="title">Vehicle Distance</h3>
                    </b-col>
                    <b-col cols="6">
                        <h3>{{ emissionStats.vehicle + "  kg CO2e/yr" }}</h3>
                    </b-col>
                    <b-col cols="6">
                        <h3 class="title">Mass-Distance</h3>
                    </b-col>
                    <b-col cols="6">
                        <h3>{{ emissionStats.totalMass + "  kg CO2e/yr" }}</h3>
                    </b-col>
                </b-row>
            </div>
            <div class="highlight-modal-footer">
                <b-button @click="hideProjectHighlightsModal" class="btn mr-60">Back</b-button>
                <b-button @click="handleExplore" class="btn">Explore</b-button>
            </div>
        </b-modal>

    </div>
</template>

<script>
import Loader from "../components/Loader";
// import axios from "../utility/axios";
import Swal from "sweetalert2";

export default {
    components: { Loader },
    data() {
        return {
            loading: false,
            emissionStats: {}
        };
    },
    name: "ProjectHighlightsModal",
    methods: {
        hideProjectHighlightsModal() {
            this.$root.$emit("bv::hide::modal", "modal-project-highlights");
        },
        async handleExplore() {
            this.$root.$emit("bv::hide::modal", "modal-project-highlights");
            await this.$store.commit("setProject", this.$store.getters.highlightsProject);
            this.$router.push("/explore")

        },
        getEmissionStats() {
            this.loading = true;
            this.axios
                .get(
                    `/projectEmissions?project_id=${this.$store.getters.highlightsProjectId}`
                )
                .then((res) => {
                    this.getProjectHighlightEmissionStats(res.data.data)
                    this.loading = false;
                })
                .catch((error) => {
                    console.log("this is called")
                    console.log("getProjects error", error);
                    Swal.fire({
                        icon: "error",
                        title: "New Error",
                        text: error.message,
                    });
                    this.loading = false;
                });
        },
        getProjectHighlightEmissionStats(stats) {
            let totalEmissionsValue = stats.totalEmissions.reduce((sum, current) => sum + current["Total Emissions"], 0)
            let newdata = {
                total: this.computeEmissions(totalEmissionsValue),
                electrical: stats.electrical ? this.computeEmissions(stats.electrical["Emissions"]) : 0,
                thermal: stats.thermal ? this.computeEmissions(stats.thermal["Emissions"]) : 0,
                totalMass: stats.totalmass ? this.computeEmissions(stats.totalmass["Emissions"]) : 0,
                vehicle: stats.vehicle ? this.computeEmissions(stats.vehicle["Emissions"]) : 0,
            }
            this.emissionStats = newdata
        },
        computeEmissions(emissionValue) {
            emissionValue = this.transformResult(emissionValue);
            return emissionValue ? emissionValue.toLocaleString('en-US') : 0
        },
		transformResult(result) {
            if(result == null)
                return 0;

			if (result < 1)
				return parseFloat(result.toFixed(2));
			else if (result <= 100)
				return parseFloat(result.toFixed(1));
			else
				return parseFloat(result.toFixed(0));
		}
    },
}

</script>

<style lang="scss">
.mr-60 {
    margin-right: 60px !important;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-30 {
    margin-bottom: 30px;
}

.padding-40 {
    padding: 0 40px;
}

.highlight-modal-title {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    color: #53607E;
    margin-bottom: 42px;
}

.highlight-modal-content {
    border-radius: 10px !important;
}

.highlight-modal-dialogue {
    max-width: 700px;
}

.stats-container {
    h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        color: #000000;
        overflow: hidden;
        overflow-wrap: break-word;
    }

    h3 {
        margin-bottom: 25px;
        color: #000000;
        overflow: hidden;
        overflow-wrap: break-word;
    }

    .title {
        color: #53607E;
    }

}

.highlight-modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;

    .btn {
        background: #FFFFFF;
        border: 1px solid #000000;
        border-radius: 10px;
        padding: 12px 24px;
        width: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
