import Vue from "vue";
import Router from "vue-router";
import firebase from "firebase";
// import firebaseConfig from "./utility/firebase";
import axios from "./utility/axios";

// firebase.initializeApp(firebaseConfig);

Vue.use(Router);

const router = new Router({
    mode: "history",
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [
        {
            path: "*",
            name: "login",
            component: () => import("./pages/Login.vue"),
        },
        {
            path: "/",
            name: "login",
            component: () => import("./pages/Login.vue"),
        },
        {
            path: "/projects",
            name: "projects",
            component: () => import("./pages/Projects.vue"),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/table",
            name: "table",
            component: () => import("./pages/Table.vue"),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/explore",
            name: "explore",
            component: () => import("./pages/Explore.vue"),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/entities",
            name: "entities",
            component: () => import("./pages/Entity.vue"),
            meta: {
                requiresAuth: true,
            },
        },
    ],
});

router.beforeEach( (to, from, next) => {
    firebase.auth().onAuthStateChanged(async (user) => {
        if (user && to.name == "login") {
            await axios.get("verifyUser?email=" + user.email)
            .then(res => {
                console.log("verifyUser res", res);
                next("projects");
            }).catch(err => {
                console.log("verifyUser err", err);
            })
        } else if (!user && to.name == "projects") {
            next("login");
        } else {
            next();
        }
    });
});

export default router;