import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from './store/store'
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
// import { ValidationProvider, ValidationObserver } from "vee-validate";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// asset imports
import "@/assets/scss/argon.scss";
import "@/assets/vendor/nucleo/css/nucleo.css";

//loader
// import 'vue-progress-path/dist/vue-progress-path.css'
// import VueProgress from 'vue-progress-path'
// Vue.use(VueProgress);

import SuiVue from 'semantic-ui-vue';
Vue.use(SuiVue);

import vueDebounce from 'vue-debounce';
Vue.use(vueDebounce, {
    defaultTime: '1500ms'
});

import GlobalComponents from "./plugins/globalComponents";
Vue.use(GlobalComponents);
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
// Vue.component("validation-provider", ValidationProvider);
// Vue.component("validation-observer", ValidationObserver);

import { FocusTrap } from 'focus-trap-vue'
Vue.component('FocusTrap', FocusTrap)

import Toasted from 'vue-toasted';
const Options = {
    duration: 5000,
    className: 'toast-message',
    closeOnSwipe: true
}
Vue.use(Toasted, Options);

Vue.config.productionTip = false;

import firebase from "firebase/app"
import "firebase/auth"
import firebaseConfig from "./utility/firebase";

firebase.initializeApp(firebaseConfig);

import axios from 'axios';
import VueAxios from 'vue-axios';

axios.defaults.baseURL = "/api";

axios.interceptors.request.use(async(request) => {
    // console.log("Inside axios.interceptors.request.use: " + JSON.stringify(request));

    const auth = firebase.auth();
    // console.log(auth);
    // console.log(auth.currentUser);

    const accessToken = await auth.currentUser.getIdToken();
    // // console.log(accessToken);
        
    await store.commit("setAccessToken", accessToken);
        
    request.headers.Authorization = "Bearer " + store.getters.accessToken;

    return request;
})

axios.interceptors.response.use((response) => {
    return response;
}, async(error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if(error.response.status == 401) {
        router.push("login");
    }
    if(error.response.status == 403) {
        router.push("projects");
    }

    return Promise.reject(error);
});

Vue.use(VueAxios, axios);

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");